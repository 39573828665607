// Generated by Framer (a94a2c0)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const cycleOrder = ["aCQ1W68Pi"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"aCQ1W68Pi": "framer-v-o7ut7y"};

if (isBrowser) removeHiddenBreakpointLayers("aCQ1W68Pi", breakpoints, variantClassNames);

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, layoutId, width, height, variant: outerVariant = "aCQ1W68Pi", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "aCQ1W68Pi", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div className="framer-8Jjnd" style={{"display": "contents"}}>
<motion.div {...restProps} className={cx("framer-o7ut7y", className)} style={{...style}} name="Desktop" transition={transition} ref={ref} {...addVariantProps("aCQ1W68Pi")}/>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-8Jjnd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", ".framer-8Jjnd .framer-o7ut7y { position: relative; overflow: hidden; width: 1280px; height: 1080px; background-color: #ffffff; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1080
 * @framerIntrinsicWidth 1280
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FramerOoqnlLLcT: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerOoqnlLLcT;

FramerOoqnlLLcT.displayName = "About";

FramerOoqnlLLcT.defaultProps = {"width": 1280, "height": 1080};

addFonts(FramerOoqnlLLcT, []);